<template>
  <div class="klubba-product-card" v-if="product" :class="{ 'show-club': showClub }">
    <!-- Club -->
    <div class="club" v-if="showClub">
      <img src="" v-image-from-storage="clubBackground" class="background" />
      <KlubbaLoaderIcon light />
      <div class="logo-wrapper">
        <img src="" v-image-from-storage="clubLogo" class="logo" />
        <KlubbaLoaderIcon light />
      </div>
      <div class="name">{{ `${club.name} Member Registration` }}</div>
    </div>

    <!-- Plan -->
    <div class="plan">
      <!-- Tick -->
      <transition name="fade">
        <img src="@/assets/icons/tick.svg" class="tick" v-if="isChecked"/>
      </transition>

      <!-- Image -->
      <div class="level-image-wrapper">
        <div class="level-image-background" v-bg-image-from-storage="backgroundImage" />
        <img
          src=""
          v-image-from-storage="image"
          class="level-image"
          alt="level image"
        />
        <KlubbaLoaderIcon />
      </div>

      <!-- Description -->
      <div class="content" v-if="product">
        <p class="title">{{ product.name }}</p>
        <p class="price">A${{ product.price }} p/m</p>
        <p class="description" v-if="product && !showClub">{{ product.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import KlubbaLoaderIcon from '../default/KlubbaLoaderIcon.vue';

export default {
  name: 'KlubbaLoaderIcon',
  components: { KlubbaLoaderIcon },
  props: {
    index: {
      type: Number,
    },
    isChecked: {
      type: Boolean,
    },
    product: {
      type: Object,
    },
    showClub: {
      type: Boolean,
    },
  },
  computed: {
    club() {
      return this.$store.getters.club;
    },
    image() {
      return this.product && this.product?.image ? this.product.image : '';
    },
    backgroundImage() {
      return this.club?.subscription_image ?? '';
    },
    clubBackground() {
      return this.club?.wallet_image ?? '';
    },
    clubLogo() {
      return this.club?.logo_image ?? '';
    },
  },
};
</script>

<style lang="sass" scoped>
.klubba-product-card
  &.show-club
    .plan
      @apply bg-white rounded-radius-10 p-4 shadow-lg -mx-2 -mt-2 relative

      border: 1px solid rgba(0, 0, 0, 0.1)

  // Club
  .club
    @apply relative overflow-hidden rounded-radius-10 bg-primary-dark flex items-center text-white px-5 pt-4 pb-6

    .logo
      @apply block flex-shrink-0 relative
      max-width: 72px
      max-height: 54px
      transform: translate3d(0, 0, 0)

    .logo-wrapper
      position: relative
      min-width: 30px
      min-height: 30px

    .name
      @apply flex-1 ml-7 relative font-bold uppercase
      font-size: 12px
      line-height: normal
      letter-spacing: 1.1px

    .background
      @apply absolute object-cover left-0 top-0 right-0 bottom-0 m-auto

  // Plan
  .tick
    @apply top-3 right-3 absolute z-10

  .level-image-wrapper
    @apply rounded-radius-10 p-2 relative bg-background overflow-hidden
    padding-bottom: 37%
    transform: translate3d(0, 0, 0)

  .level-image-background
    @apply absolute left-0 top-0 right-0 bottom-0
    transform: translate3d(0, 0, 0)
    background-position: center
    background-size: cover

  .level-image
    @apply h-11 rounded-radius-10 w-24 object-contain cursor-pointer m-auto absolute left-0 top-0 bottom-0 right-0 m-auto

  .content
    @apply mt-5 px-1 pb-1

  .title
    @apply font-bold
    font-size: 22px
    line-height: 26px
    letter-spacing: 0.5px

  .price
    @apply mt-3 font-semibold

  .description, .price
    line-height: normal
    letter-spacing: 0.8px

  .description
    @apply mt-4
</style>

<template>
  <div class="page-account-review page-content">
    <KlubbaRichText class="texts">
      <p><b>{{ title }}</b></p>
      <p>{{ description }}</p>
    </KlubbaRichText>

    <div class="container">
      <!-- Payment problems -->
      <div class="pay-again" v-if="!paymentSuccess && userSubscription?.stripe_data?.payment_status !== 'paid'">
        <KlubbaRichText>
          <p><b>There was a problem with the selected payment method</b></p>
          <p>Transaction was declined. Please press the button below to pay and renew your subscription.</p>
        </KlubbaRichText>
        <klubba-button
          :loading="renewLoading"
          theme="danger"
          class="mt-8"
          :buttonText="`Pay $${userSubscription?.price} AUD`"
          @click="renewSubscription"
        />
      </div>

      <!-- Card preview -->
      <klubba-product-card
        v-if="userSubscription"
        :product="subscription"
        class="klubba-product-card"
        :showClub="true"
      />
    </div>

    <div class="button-wrap">
      <klubba-button
        :loading="!userSubscription?.enrollment?.id"
        :childClass="childClass"
        :icon="icon"
        :iconClass="iconClass"
        theme="black"
        :href="`https://pub1.pskt.io/${userSubscription?.enrollment?.id}`"
        target="_blank"
        tag="a"
        @click="addedToWallet = true"
      />
      <klubba-button
        v-if="paymentSuccess && addedToWallet"
        class="mt-4"
        buttonText="Finished"
        @click="finished"
      />
      <router-link v-if="!paymentSuccess && userSubscription" class="cancel" :to="`/${club.id}/cancel-request-code`">Cancel subscription</router-link>
    </div>

    <transition name="fade">
      <div v-if="loading" class="loading">
        <KlubbaLoaderIcon :active="true" light />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import swal from 'sweetalert';
import KlubbaButton from '@/components/default/KlubbaButton.vue';
import KlubbaProductCard from '@/components/custom/KlubbaProductCard.vue';
import KlubbaRichText from '@/components/default/KlubbaRichText.vue';
import KlubbaLoaderIcon from '../../components/default/KlubbaLoaderIcon.vue';
import firebase from '../../config/firebase';

export default {
  components: {
    KlubbaLoaderIcon,
    KlubbaRichText,
    KlubbaButton,
    KlubbaProductCard,
  },
  data() {
    return {
      childClass: 'flex text-white px-2',
      icon: 'apple-wallet.svg',
      iconClass: 'h-11',
      paymentSuccess: false,
      loading: false,
      addedToWallet: false,
      renewLoading: false,
    };
  },
  computed: {
    ...mapGetters(['club', 'platform', 'firebaseUser', 'userSubscription', 'billingInfo']),
    title() {
      if (this.paymentSuccess) return 'Payment received';
      return this.userSubscription ? 'Your subscription' : 'We are processing your subscription';
    },
    description() {
      if (!this.userSubscription) return 'Please stay on the page, your subscription will appear soon.';
      return 'Download your wallet pass to your phone. This is your digital membership card.';
    },
    subscription() {
      return this.$store.getters.selectedClubSubscription;
    },
  },
  mounted() {
    this.checkSuccess();
  },
  methods: {
    async checkSuccess() {
      const query = { ...this.$route.query };
      if (query?.paymentSuccess) {
        this.paymentSuccess = true;

        // Remove the parameter from URL
        delete query.paymentSuccess;
        await this.$router.replace({ path: this.$route.path, query: { ...query } });
      }
    },
    finished() {
      this.$router.push(`/${this.$route.params.id}/subscription-added`);
    },
    async renewSubscription() {
      if (this.userSubscription?.stripe_data?.invoice) {
        this.renewLoading = true;

        const renewSubscription = firebase.functions()
          .httpsCallable('repeatPayment');

        try {
          const result = await renewSubscription({ invoice_id: this.userSubscription?.stripe_data?.invoice });
          if (result.data?.isSuccess === true) {
            document.location.href = result.data.result.url;
          } else {
            console.log(result);
            swal('Error', 'Please try again later or contact support', 'error');
          }
        } catch (error) {
          console.log(error);
          await swal('Error', 'Please try again later or contact support', 'error');
        }

        this.renewLoading = false;
      } else {
        console.log('No invoice id');
        await swal('Error', 'Please try again later or contact support', 'error');
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.texts
  @apply p-8

.container
  @apply px-8

.klubba-product-card
  @apply -mx-1

.button-wrap
  @apply p-8 mt-auto

.loading
  @apply flex items-center justify-center fixed top-0 left-0 m-0 w-full bg-primary-dark z-50
  height: calc(var(--vh, 1vh) * 100)

.cancel
  @apply text-center mt-4 font-medium opacity-50 block

.pay-again
  @apply rounded-radius-10 border border-solid p-6 mb-8 text-danger border-danger bg-danger bg-opacity-10
</style>
